@import '../../../../styles/customMediaQueries.css';

.formClassName {
  height: 46px;
  padding: 2px;
  flex-grow: 1;
  max-width: 550px;
  /* max-width: calc(555px * 1.4); */

  gap: 12px;
  display: flex;
  border-radius: 8px;
  /* background-color: yellowgreen; */

  @media (--viewportMaxLarge) {
    height: 42px;
  }

  @media (--vwMaxM) {
    height: auto;
  }
}

.locationSearchBox {
  width: 30%;
  position: relative;
  padding-right: 2rem;
  padding-left: calc(8px + 3px);

  gap: 8px;
  display: flex;
  align-items: center;

  border-radius: 8px;
  background-color: var(--colorWhite);

  & svg {
    width: 40px;
    height: 40px;
    fill: transparent;
    /* background-color: blue; */
  }

  & input {
    padding: 0;
    height: 100%;
    border: none;
    /* background-color: blue; */

    &:focus {
      box-shadow: none;
    }
  }

  @media (--vwMaxM) {
    width: 100%;
    height: 36px;
  }

  @media (--viewportMaxLarge) {
    padding-left: 8px;

    & svg {
      width: 24px;
      height: 24px;
    }
  }

  /* @media (--vwM) and (--viewportMaxLarge) { */
  @media (--viewportMaxLarge) {
    padding-left: calc(8px + 2px);
    border: 1px solid var(--marketplaceColorDark);
  }
}

.parentKeywordSearchBox {
  padding: 3px;
  flex-grow: 1;

  gap: 8px;
  display: flex;
  border-radius: 8px;
  background-color: var(--colorWhite);

  @media (--viewportMaxLarge) {
    padding: 0px;
  }

  @media (--vwMaxM) {
    height: 36px;
  }

  /* @media (--vwM) and (--viewportMaxLarge) { */
  @media (--viewportMaxLarge) {
    padding: 2px;
    min-height: 40px;
    border: 1px solid var(--marketplaceColorDark);
  }
}

.keywordSearchBox {
  flex-grow: 1;
  padding-left: 8px;
  position: relative;

  gap: 8px;
  display: flex;
  align-items: center;
  /* background-color: brown; */

  & .xMarkSVG {
    right: 0;
  }

  & svg {
    width: 24px;
    height: 24px;
    fill: transparent;
  }
}

.locationIcon {
  min-width: 20px;
  min-height: 20px;
  /* background-color: orange; */

  & path {
    fill: #bdbdbd;
  }
}

.keywordInput {
  width: 100%;
  height: 100%;
  /* background-color: blue; */

  & input {
    height: 100%;
    padding: 0px;
    padding-right: 2rem;

    border: none;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: var(--colorTextGray);
    /* background-color: red; */

    &:focus {
      box-shadow: none;
    }
  }
}

.xMarkSVG {
  right: 10px;
  cursor: pointer;
  position: absolute;
  width: 20px !important;
  height: 20px !important;

  & > path {
    transition: all 0.2s ease;
    stroke: var(--colorTextLightGray);
  }

  &:hover {
    & > path {
      stroke: var(--colorTextGray);
    }
  }
}

.searchBtn {
  padding: 0px 8px;

  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  background-color: var(--marketplaceColor);

  & svg {
    width: 22px;
    height: 22px;
    fill: transparent;
  }

  @media (--viewportMaxLarge) {
    background-color: var(--marketplaceColorDark);
  }
}

.mobileIcon {
  height: 100%;
  display: none;
  /* display: flex; */
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: var(--marketplaceBGColor);

  & svg {
    fill: none;
  }

  & span {
    display: none;
  }

  @media (max-width: 767.98px) {
    flex-basis: 8%;
  }

  @media (max-width: 575.98px) {
    flex-basis: 10%;
  }
}

.searchInputDiv {
  gap: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
  /* background-color: violet; */

  @media (max-width: 767px) {
    gap: 0px;
    padding: 4px;
    display: flex;
    flex-direction: column-reverse;

    border-radius: 8px;
    border: 1px solid #9d9d9d;
    /* background-color: violet; */
  }
}

.hDivider {
  height: 100%;
  border: 1px solid var(--colorLightGray);

  @media (max-width: 767px) {
    width: 100%;
    height: 0px;
    border: 1px solid var(--colorLightGray);
  }
}

.mobileInputRoot {
  width: 100%;
  height: 100%;

  @media (max-width: 767.98px) {
    /* flex-basis: 90%; */
  }

  @media (max-width: 575.98px) {
    /* flex-basis: 88%; */
  }
}

.mobileInput {
  margin: 0;
  padding: 0;
  height: 100%;
  border-bottom: none;
  /* flex-grow: 1; */
  /* flex-basis: 60%; */

  /* Font */
  /* composes: h1 from global; */

  /* Layout */
  /* padding: 4px 0 10px; */

  /* font-size: 24px; */
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.5px;

  /* Borders */
  overflow-x: hidden;
  text-overflow: ellipsis;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    outline: none;
    border-bottom-color: var(--marketplaceColor);
  }

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: var(--colorTextLightGray);
    /* color: var(--marketplacePlaceholder); */
  }
}

.desktopIcon {
  display: none !important;

  padding: 0 20px;
  font-size: 16px;
  min-height: 42px;
  margin: 0 0 0 10px;
  border-radius: 8px;
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    display: none !important;
  }
}

.desktopInput {
  /* composes: h4 from global; */
  /* flex-grow: 1;
  max-height: 42px;
  line-height: unset;
  border: none;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0; */

  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  flex-grow: 1;

  border: none;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-weight: 400 !important;
  color: var(--colorTextGray);
  /* background-color: red; */

  &:hover,
  &:focus {
    border: none;
    box-shadow: none;

    /* outline: none;
    border-bottom-color: var(--marketplaceColor); */
  }

  &:hover::placeholder,
  &:focus::placeholder {
    /* color: var(--matterColor); */
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
  }
}

.mobilePredictions {
  /* top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder)); */
  left: 0;
  top: 50px;
  min-width: 300px;
  position: absolute;

  border-radius: 8px;
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexTopbar));
  background-color: var(--marketplaceColor);

  @media (max-width: 767px) {
    width: 100%;
    min-width: unset;
    /* height: max-content !important; */
  }
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  left: 0;
  top: 50px;
  width: 100%;
  font-size: 12px;
  min-width: 300px;
  position: absolute;
  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );

  border-radius: 8px;
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexTopbar));
  background-color: var(--marketplaceColor);

  @media (max-width: 767px) {
    left: 0;
    top: 40px;
    min-width: 250px;
  }

  @media (max-width: 575px) {
    left: 5%;
    right: 5%;
    top: 100px;
    width: 90%;
    position: fixed;
  }
}
