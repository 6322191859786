@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;

  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: blanchedalmond; */
}

.filters {
  gap: 1rem;
  display: flex;

  /* & > *:not(:last-child) {
    height: auto;
    margin-right: 1rem;
  } */
}

.buttonsWrapper {
  /* background-color: red; */
}

.resetButton {
  /* Layout */
  margin: 0;
  width: auto;
  min-height: 42px;
  padding: 0px 12px;

  /* Font */
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;

  /* Button borders */
  border-radius: 8px;
  border: 1px solid transparent;

  /* Button colors */
  color: var(--colorWhite);
  background-color: var(--marketplaceColor);

  &:hover,
  &:focus {
    box-shadow: none;
    border: 1px solid transparent;
    background-color: var(--marketplaceColorDark);
  }
}
